import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type {
  CommentButtonImpressionEvents,
  CommentButtonLocation,
} from "~/components/tracking/google-analytics/types";
import { sendGA4Tracking } from "~/components/tracking/google-analytics-4/apis";
import type { CommentWidgetPosition } from "~/components/tracking/google-analytics-4/types";
import { useArticleCommentLink } from "~/lib/hooks";
import type { articleCommentsTriggerArticle$key } from "~/queries/__generated__/articleCommentsTriggerArticle.graphql";

import { ArticleCommentsBanner } from "./banner";
import { ArticleCommentsButton } from "./button";
import { ArticleCommentsIcon } from "./icon";
import type { Props as ArticleCommentsWidgetProps } from "./widget";
import { ArticleCommentsWidget } from "./widget";

export type Props = {
  className?: string;
  location: CommentButtonLocation;
  onClick?: () => void;
  reference: articleCommentsTriggerArticle$key;
  variant: "widgetDark" | "widgetLight" | "button" | "banner" | "icon" | "live-article-item";
};

export const ArticleCommentsTrigger: FunctionComponent<Props> = ({
  className,
  location,
  onClick,
  reference: reference_,
  variant,
}) => {
  const { commentCount, entityId } = useFragment<articleCommentsTriggerArticle$key>(
    graphql`
      fragment articleCommentsTriggerArticle on Article {
        commentCount
        entityId
      }
    `,
    reference_,
  );

  const impressionEvent = useCallback<() => CommentButtonImpressionEvents>(
    () => ({
      action: "Comment button/Impression",
      category: "Comment",
      label: `Comment button/Impression/${location}`,
    }),
    [location],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    CommentButtonImpressionEvents,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });
  const { toggleByUrl: toggleCommentByUrl } = useArticleCommentLink();

  const handleOpenComments = (trackingPosition: CommentWidgetPosition) => {
    sendGATracking({
      action: "Comment button/Click",
      category: "Comment",
      label: `Comment button/Click/${location}`,
    });
    toggleCommentByUrl();

    sendGA4Tracking({
      action: "click",
      category: "comment",
      customized_parameters: {
        article_id: entityId,
        position: trackingPosition,
      },
      subcategory: "widget",
    });
    onClick?.();
  };

  switch (variant) {
    case "button":
      return (
        <ArticleCommentsButton
          className={className}
          commentCount={commentCount ?? 0}
          ref={captureTrackImpressionEventTargetElement}
          onClick={() => handleOpenComments("adhesive")}
        />
      );
    case "banner":
      return (
        <ArticleCommentsBanner
          className={className}
          commentCount={commentCount ?? 0}
          ref={captureTrackImpressionEventTargetElement}
          onClick={() => handleOpenComments("article_bottom")}
        />
      );
    case "icon":
      return (
        <ArticleCommentsIcon
          className={className}
          ref={captureTrackImpressionEventTargetElement}
          onClick={() => handleOpenComments("article_bottom")}
        />
      );
    case "live-article-item":
      return (
        <ArticleCommentsIcon
          className={className}
          ref={captureTrackImpressionEventTargetElement}
          onClick={() => {
            toggleCommentByUrl();
            onClick?.();
          }}
        />
      );
    case "widgetDark":
    case "widgetLight":
      const widgetVariant: ArticleCommentsWidgetProps["variant"] =
        variant === "widgetDark" ? "dark" : "light";
      return (
        <ArticleCommentsWidget
          className={className}
          commentCount={commentCount ?? 0}
          ref={captureTrackImpressionEventTargetElement}
          variant={widgetVariant}
          onClick={() => handleOpenComments("article_side")}
        />
      );
    default:
      return null;
  }
};
